import * as React from 'react'
import * as API from '../../Checkout/lib/api'
import { CheckIcon2024 } from '../../igniter/Icons'
import { parameterize } from '../../Utils/StringUtils'

interface IProps {
  header: string | React.ReactNode
  includedItems: API.IIncludedItem[]
  planName: string
}

export function IncludedItems({ header, includedItems, planName }: IProps) {

  const includedItemListItems = includedItems.map(({ name, new: isNew }) => {
    return (
      <li key={parameterize(name)} className={parameterize(name)}>
        <CheckIcon2024 />
        {name}
        <span className="new">{isNew ? 'New' : ''}</span>
      </li>
    )
  })

  if (includedItems.length === 0) { return null }

  const itemsHeader = planName === 'Pro'
    ? (
      <>
        Everything in <span>Standard</span> plus all:
      </>
    )
    : header
  return (
    <div className="section middle">
      <div className="spts">
        <h4>
          {itemsHeader}
        </h4>
        <ul className="product-types">
          {includedItemListItems}
        </ul>
      </div>
      {planName === 'Starter' && (
        <div className="spts">
          <h4>
            Includes access to:
          </h4>
          <ul className="product-types">
            <li>
              Stills & Motions
            </li>
            <li>
              Photoshop assets
            </li>
            <li>
              After Effects assets
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

IncludedItems.defaultProps = {
  header: 'Unlimited access to all:',
}
