import * as React from 'react'
import { PurchaseOption } from '../../Checkout/lib/purchase_option'
import { TrialActivationButton } from '../../TrialActivationButton'

declare var Routes: any

interface IProps {
  purchaseOption: PurchaseOption
  transition: string
  transitionCTA: string
  renewalDate: string
  trialEligible?: boolean
  trialActivationPrice?: number
}

export function CallToAction(props: IProps) {
  const {
    purchaseOption,
    transition,
    transitionCTA,
    renewalDate,
    trialEligible,
    trialActivationPrice,
  } = props
  const { membershipPlan, paymentOption } = purchaseOption
  const renewalDateString = new Date(renewalDate).toLocaleDateString()
  let callToAction

  if (trialEligible) {
    return <FreeTrialSignupButton purchaseOption={purchaseOption}/>
  }

  switch (transitionCTA) {
    case 'trial_activation':
      if (purchaseOption.sameAsCurrentBilling) {
        callToAction = (
          <TrialActivationButton
            planName={membershipPlan.name}
            planPrice={trialActivationPrice}
            installmentCount={paymentOption.numberOfPayments}
          />
        )
      } else {
        callToAction = (
          <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Select Plan"/>
        )
      }
      break
    case 'select':
      callToAction = (
        <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Select Plan"/>
      )
      break
    case 'switch_and_renew':
      callToAction = (
        <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Switch &amp; Renew"/>
      )
      break
    case 'upgrade':
      callToAction = (
        <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Upgrade"/>
      )
      break
    case 'downgrade':
      callToAction = (
        <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Downgrade"/>
      )
      break
    case 'renewal':
      callToAction = (
        <MembershipPlanSignupButton purchaseOption={purchaseOption} text="Renew & Save!"/>
      )
      break
    case 'not_eligible':
      callToAction = (
        <div className="transition-unavailable">
          {`Your membership will be eligible for ${transition} on ${renewalDateString}.`}
        </div>
      )
      break
  }

  return callToAction
}

function MembershipPlanSignupButton({ text, purchaseOption }: { text: string, purchaseOption: PurchaseOption}) {
  const { code } = purchaseOption.membershipPlan

  return (
    <form action={Routes.igniter_membership_checkout_path()} method="get">
      <input type="hidden" name="plan_code" value={code} />
      <MonthlyHiddenField isMonthly={purchaseOption.name === 'monthly'} />
      <input
        disabled={code === 'PICK1'}
        className={'button primary'}
        type="submit"
        value={text}
      />
    </form>
  )
}

function FreeTrialSignupButton({ purchaseOption }: { purchaseOption: PurchaseOption  }) {
  const { code } = purchaseOption.membershipPlan
  const disabled = code === 'PICK1'

  return (
    <form action={Routes.igniter_membership_checkout_path()} method="get">
      <input type="hidden" name="plan_code" value={code} />
      <input type="hidden" name="trial" value="1" />
      <MonthlyHiddenField isMonthly={purchaseOption.name === 'monthly'} />
      <input
        disabled={disabled}
        className={'button primary'}
        type="submit"
        value="Start Trial"
      />
    </form>
  )
}

function MonthlyHiddenField({ isMonthly }: { isMonthly: boolean }) {
  if (!isMonthly) { return null }

  return (
    <input type="hidden" name="monthly" value="1" />
  )
}
